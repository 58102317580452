<div class="layout-wrapper relative" [ngClass]="{'layout-overlay': isOverlay(),
                                'layout-static': isStatic(),
                                'layout-theme-light':!config.dark,
                                'layout-theme-dark':config.dark,
                                'layout-overlay-sidebar-active': overlayMenuActive,
                                'layout-static-sidebar-inactive': staticMenuInactive,
                                'layout-mobile-sidebar-active':menuActiveMobile,
                                'p-ripple-disabled': !config.ripple, 'p-input-filled': config.inputStyle === 'filled'}">
    <div class="layout-sidebar p-0">
        <uikit-topbar [parentRef]="this" [navbar]="navbar" [authService]="authService"></uikit-topbar>
        <uikit-menu [menuItems]="menuItems" [menuBottomItems]="menuBottomItems" [parentRef]="this"></uikit-menu>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <a class="hidden fe-toggle-sidebar" (click)="toggleMenu($event)">
                <i class="pi pi-bars"></i>
            </a>
            <router-outlet></router-outlet>
            <qatch-chat></qatch-chat>
        </div>
    </div>
    <h-theme view="icon" (configClick)="onConfigClick($event)"></h-theme>
    <div *ngIf="menuActiveMobile" class="layout-mask p-component-overlay"></div>
</div>

<p-toast/>
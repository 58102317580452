import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Carousel } from 'primeng/carousel';
import { interval, Subscription } from 'rxjs';

export interface SMSProps {
  title: string;
  subTitle: string;
  message: string;
  icon: string;
  time: string;
}

@Component({
  selector: 'lib-sms-gallery',
  templateUrl: './sms-gallery.component.html',
  styleUrls: ['./sms-gallery.component.scss']
})
export class SmsGalleryComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() smsList: SMSProps[] = [];
  @Input() antiClockwise;
  @ViewChild(Carousel) carousel: Carousel;
  intervalRef: Subscription;
  moveForDir = true;
  isMobile = false;
  constructor() { 
    this.isMobile = (innerWidth < 600);
  }

  ngOnInit(): void {
    this.intervalRef = interval(( this.isMobile ? 4 : 6) * 1000).subscribe(() =>{
      if (this.carousel.isForwardNavDisabled() && this.moveForDir) {
        this.moveForDir = false;
      }
      if (this.carousel.isBackwardNavDisabled() && !this.moveForDir) {
        this.moveForDir = true;
      }
      if (this.moveForDir) {
        this.carousel.navForward(null, this.carousel._page + 1);
      } else {
        this.carousel.navBackward(null, this.carousel._page - 1);
      }
    })
  }

  ngAfterViewInit(): void {
    if (this.antiClockwise) {
      this.moveForDir = false;
      this.carousel.navForward(null, this.carousel.totalDots());
    }
  }

  ngOnDestroy(): void {
    this.intervalRef?.unsubscribe();
  }

}

import { AfterViewInit, Directive, ElementRef, OnInit, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[lazyLoadImage]'
})
export class LazyLoadImageDirective implements AfterViewInit {
  loaded = false;
  @Input() url = '';
  @Input() defaultUrl = './assets/img/icons/iphone-spinner.gif';
  @Input() img = true;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
    ) {
    
  }

  ngAfterViewInit(): void {
    this.setUrl();
    document.querySelector('.product-list-custom-scroll-container')?.addEventListener('scroll', () => {
      this.setUrl();
    });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.setUrl();
  }

  setUrl() {
    if(this.loaded && this.el.nativeElement.className.includes('Q_ITEM_LOADED')) {
     return;
    }
    const visible = this.isInViewport(this.el.nativeElement);
    if(visible) {
     this.loaded = true;
     this.renderer.addClass(this.el.nativeElement, 'Q_ITEM_LOADED');
    }
    if(this.img) {
      this.renderer.addClass(this.el.nativeElement.parentElement, 'qatch-skeleton');
      if(visible) {
        this.renderer.setAttribute(this.el.nativeElement, 'src', this.url);
        setTimeout(() => {
          this.renderer.removeClass(this.el.nativeElement.parentElement, 'qatch-skeleton');
        }, 500);
      }
    } else {
      this.renderer.setStyle(this.el.nativeElement, 'background-size', `${visible ? 'cover' : 'auto'}`);
      this.renderer.setStyle(this.el.nativeElement, 'background-image', `url('${visible ? this.url : this.defaultUrl}')`);
    }
  }

  private isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        ((rect.bottom - rect.height) <= (window.innerHeight || document.documentElement.clientHeight)) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

}
